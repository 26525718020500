import React from "react";
import MainHeader from "../main-header/main-header";
import SubHeader from "../sub-header/sub-header";
import Text from "../text/text";
import NavBar from "../navbar/navbar";
import "./page-component.css";
import ContactPage from "../contact-page/contact-page";
import Footer from "../footer/footer";

class PageComponent extends React.Component {
  render() {
    window.scrollTo(0, 0);
    const { image, header, headerContent, subHeader, points, imageArray } =
      this.props;
    console.log(imageArray);
    const arrayDataItems = points.map(({ header, subHeader, point }, index) => (
      <li key={index} className="flex flex-col gap-3">
        {header ? (
          <SubHeader text={header} />
        ) : (
          <>
            <p className="text-xl text-left" style={{ fontWeight: "800" }}>
              {subHeader}
            </p>
          </>
        )}

        <ul className="flex flex-col gap-3">
          {point.map((entry, entryIndex) => (
            <li key={entryIndex}>
              <Text text={entry} />
            </li>
          ))}
        </ul>
      </li>
    ));
    return (
      <>
        <div className="">
          <div className="sticky top-0 bg-white z-50 shadow-sm">
            <NavBar />
          </div>
          <div className="py-16 px-20 flex flex-col gap-10">
            <div>
              {imageArray ? (
                <div className="flex flex-wrap justify-center items-center rounded-md overflow-hidden h-[480px]">
                  {imageArray.map((image, index) => (
                    <img
                      key={index}
                      className="object-cover h-1/4 w-1/4"
                      src={image}
                      alt="page_image"
                    />
                  ))}
                </div>
              ) : (
                <img
                  className="w-full h-[480px] object-cover rounded-md"
                  src={image}
                  alt="page_image"
                />
              )}
            </div>

            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-3">
                <MainHeader text={header} />
                <Text text={headerContent} />
              </div>
              <SubHeader text={subHeader} />
              <div className="w-full">
                <ul className="grid grid-cols-2 gap-y-6 gap-x-8 w-full grid-container">
                  {arrayDataItems}
                </ul>
              </div>
            </div>
          </div>
        </div>
        
        {/* Contact Page */}
        <ContactPage />

        {/* Footer */}
        <Footer />
      </>
    );
  }
}

export default PageComponent;
