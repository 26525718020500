import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    const textStyle = {
      color: "#787878",
      fontSize: "16px",
      fontWeight: "400",
    };
    return (
      <div className="px-20 py-4 flex justify-between">
        <p style={textStyle}>&#169; 2024 Carbonix</p>
        <Link to={'/privacy-policy'}>
        <p style={textStyle}>Privacy Policy</p>
        </Link>
      </div>
    );
  }
}


export default Footer;