import React from "react";
import logo from "../../assets/carbonix_logo.svg";
// import langIcon from '../../assets/lang_icon.svg'
import { Link } from "react-router-dom";

// const languageStyle = {
//     color: "var(--systemGrey-700, #616161)",
//     fontFamily: "Inter",
//     fontSize: "16px",
//     fontStyle: "normal",
//     fontWeight: "700",
//     lineHeight: "normal",
//     letterSpacing: "0.2px",
//     textTransform: "uppercase"
// }

const buttonStyle = {
  color: "#FFFFFF",
  display: "flex",
  padding: "8px 22px",
  alignItems: "center",
  justifyContent:"center",
  gap: "10px",
  borderRadius: "5px",
  background:"#AA5647"
};

const scrollDown = ()=>{
  const pageHeight = document.documentElement.scrollHeight;
  window.scrollTo({
    top: pageHeight,
    behavior: 'smooth',
  });
}



class NavBar extends React.Component {
  render() {
    return (
      <>
        <div className="flex justify-between items-center px-20 py-3.5">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
          <div className="flex items-center gap-5">
            {/* <div className="flex items-center gap-2">
                        <img src={langIcon} alt="lang-icon"/>
                        <p style={languageStyle}>EN</p>
                        </div> */}
            {/* <Link to={"/contact"}> */}
              <button style={buttonStyle} onClick={scrollDown}>Contact us</button>
            {/* </Link> */}
          </div>
        </div>
      </>
    );
  }
}

export default NavBar;
