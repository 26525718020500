import React from "react";
import MainHeader from "../main-header/main-header";
import SubHeader from "../sub-header/sub-header";
import Text from "../text/text";
import "./detail-component.css";
import { Link } from "react-router-dom";
import {
  collageImageArray
} from "../../site-data";

class DetailsComponent extends React.Component {
  render() {
    const {
      pageHeader,
      image,
      header,
      contentText,
      subHeader,
      points,
      buttonName,
      imageCollage,
    } = this.props;


    const arrayDataItems = points.map(({ header, subHeader, point }, index) => (
      <li key={index} className="flex flex-col gap-3">
        {header ? (
          <SubHeader text={header} />
        ) : (
          <>
            <p className="text-xl text-left" style={{ fontWeight: "800" }}>
              {subHeader}
            </p>
          </>
        )}

        <ul className="flex flex-col gap-3">
          {point.map((entry, entryIndex) => (
            <li key={entryIndex}>
              <Text text={entry} />
            </li>
          ))}
        </ul>
      </li>
    ));

    let inputSubHeader;

    if (subHeader) {
      inputSubHeader = <SubHeader text={subHeader} />;
    } else {
      inputSubHeader = null;
    }

    return (
      <>
        <div className="relative w-full h-full content-container">
          <div className="flex flex-col gap-6 w-1/2 pr-10 content">
            <div className="flex flex-col gap-6">
              <MainHeader text={header} />
              <Text text={contentText} />
              <div>{inputSubHeader}</div>
              <ul className="flex flex-col gap-6">{arrayDataItems}</ul>
            </div>
            <div className="flex justify-left">
              <Link to={`/${pageHeader}`}>
                <button
                  style={{ textUnderlineOffset: "3px", fontWeight: "500" }}
                  className="text-blue-700  underline text-xl"
                >
                  {buttonName} &#x2192;
                </button>
              </Link>
            </div>
          </div>
          <div className="flex w-1/2 h-full rounded-md overflow-hidden absolute top-0 right-0 content-image-container">
            {imageCollage ? (
              <div className="grid grid-cols-4 justify-center items-center">
                {collageImageArray.map((image, index) => (
                  <img
                    key={index}
                    className="w-full h-full object-cover"
                    src={image}
                    alt={`collage-${index + 1}`}
                  />
                ))}
              </div>
            ) : (
              <img className="w-full h-full object-cover" src={image} alt="" />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default DetailsComponent;
